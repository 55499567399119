import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import Layout from "../../components/Layout";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import Breadcrumbs from "../../components/Breadcrumbs";
import useSiteMetadata from "../../components/SiteMetadata";

export default () => {
  const { siteUrl } = useSiteMetadata();
  const { purchaseSuccessImage } = useStaticQuery(graphql`
    query {
      purchaseSuccessImage: file(relativePath: { eq: "purchare-success.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <Layout whiteHeader>
      <GatsbySeo
        title={`ThankYou`}
        description={`Your product is expected to be delivered in 7 working days.You
              should recieve a confirmation on your provided email and mobile
              number!`}
        openGraph={{
          url: `${siteUrl}/shop/thankyou`,
          title: `ThankYou`,
          description: `Your product is expected to be delivered in 7 working days.You
              should recieve a confirmation on your provided email and mobile
              number!`,
          images: [{ url: purchaseSuccessImage.childImageSharp.fluid.src }],
          site_name: "MycoForest"
        }}
      />
      <section>
        <Breadcrumbs
          items={[
            { title: "MycoForest", href: "/", active: true },
            { title: "Shop", href: "/shop", active: true },
            { title: "ThankYou", href: `/shop/thankyou`, active: false }
          ]}
        />
        <div className="container">
          <div className="image-container">
            <PreviewCompatibleImage
              imageInfo={purchaseSuccessImage}
              alt="thanks for your order image"
            />
            <h1>Thanks for your order!</h1>
            <p>
              Your product is expected to be delivered in 7 working days.You
              should recieve a confirmation on your provided email and mobile
              number!
            </p>
          </div>
        </div>
        <style jsx>{`
          section {
            padding: 32px;
          }

          .container {
            max-width: 1278px;
            margin: 0 auto;
          }

          .image-container {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            text-align: center;
            font-family: "Open Sans", sans-serif;
          }

          h1 {
            margin: 0;
          }

          p {
            margin: 8px auto;
            max-width: 400px;
          }

          p span {
            font-weight: bold;
          }
        `}</style>
      </section>
    </Layout>
  );
};
